<div class="bell-modal alert {{type}} {{size}}">
  <div class="bell-modal__header" *ngIf="showTitle">
    <h2 class="bell-modal__title">{{ title }}</h2>
    <fa-icon class="bell-modal__close" [icon]="['fas', 'close']" (click)="close()"></fa-icon>
  </div>
  <div class="bell-modal__body" [innerHtml]="body"></div>
  <div class="bell-modal__footer">
    <button
      (click)="onClick(button)"
      class="primary">
      {{ button.text }}
    </button>
  </div>
</div>

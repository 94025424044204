import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalButton } from '../../models/button';
import { ModalErrors } from '../../models/modal-errors';

@Component({
  selector: 'bell-error',
  templateUrl: './error.component.html'
})
export class BellErrorComponent implements OnInit {
  @ViewChild('focusElement', {static: true}) focusElement: any;
  errorMessage: string | undefined;
  formattedErrors: Array<string> = [];

  @Input() title: string = 'Error';
  @Input() buttons: ModalButton[] = [];
  @Input() showTitle: boolean = true;
  @Input() size: 'small' | 'medium' | 'large' | 'extra-large' = 'small';

  @Input()
  set errors(errorList: ModalErrors) {
    this.errorMessage = errorList.error['error'];
    this.formattedErrors = errorList.error['message'];
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key && event.key.toLowerCase() === 'enter') {
      event.preventDefault();
      return false;
    }else {
      return;
    }
  }

  constructor(
      public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    if (!this.buttons) {
      this.buttons = [
        {
          text: 'Close',
          role: 'cancel'
        }
      ];
    }

    setTimeout(() => {
      this.focusElement.nativeElement.focus();
    }, 500);
  }

  close() {
    this.activeModal.dismiss();
  }

  onClick(button: ModalButton) {
    if (button.role === 'cancel') {
      this.activeModal.dismiss();
    }
    if (button.handler) {
      button.handler();
      this.activeModal.close();
    }
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalButton } from '../../models/button';

@Component({
  selector: 'bell-confirm',
  templateUrl: './confirm.component.html'
})
export class BellConfirmComponent implements OnInit {
  @Input() type: 'default' | 'danger' | 'warning' = 'default';
  @Input() title: string = '';
  @Input() body: string | undefined;
  @Input() buttons: ModalButton[] = [];
  @Input() showTitle: boolean = true;
  @Input() size: 'small' | 'medium' | 'large' | 'extra-large' = 'small';

  constructor(
      public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    if (this.title === '') {this.showTitle = false; }
  }

  close() {
    this.activeModal.dismiss();
  }

  onClick(button: ModalButton) {
    if (button.role === 'cancel') {
      if (button.handler) {
        button.handler();
        this.activeModal.close();
      }else {
        this.activeModal.dismiss();
        return;
      }
    }
    if (button.role !== 'cancel') {
      this.activeModal.close(button.role);
    }
    if (button.handler) {
      button.handler();
      this.activeModal.close();
    }
  }

}

import { Injectable } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BellAlertComponent } from './components/alert/alert.component';
import { BellConfirmComponent } from './components/confirm/confirm.component';
import { BellErrorComponent } from './components/error/error.component';
import { AlertModalOptions } from './models/alert-options';
import { ConfirmOptions } from './models/confirm-options';
import { CustomOptions } from './models/custom-options';
import { ErrorOptions } from './models/error-options';
@Injectable({
  providedIn: 'root',
  deps: [
    NgbActiveModal,
    NgbModal
  ]
})
export class BellModalService {

  constructor(
    private modalService: NgbModal,
    private activeModal: NgbActiveModal
  ) { }

  open(content: any, options?: CustomOptions) {
    const params: any = {};
    if (options?.disableOffclick) {
      params.windowClass = 'click-disabled';
    }
    return this.modalService.open(content, params);
  }

  alert(config: AlertModalOptions) {
    const params: any = {};
    if (config.disableOffclick) {
      params.windowClass = 'click-disabled';
    }
    const modalRef = this.modalService.open( BellAlertComponent , params );
    modalRef.componentInstance.type = config.type;
    modalRef.componentInstance.body = config.body;
    modalRef.componentInstance.button = config.button;
    if (config.title) {
      modalRef.componentInstance.title = config.title;
    }
    if (config.size) {
      modalRef.componentInstance.size = config.size;
    }
  }

  confirm(config: ConfirmOptions) {
    const params: any = {};
    if (config.disableOffclick) {
      params.windowClass = 'click-disabled';
    }
    const modalRef = this.modalService.open( BellConfirmComponent, params);
    modalRef.componentInstance.type = config.type;
    modalRef.componentInstance.body = config.body;
    modalRef.componentInstance.buttons = config.buttons;
    if (config.title) {
      modalRef.componentInstance.title = config.title;
    }
    if (config.size) {
      modalRef.componentInstance.size = config.size;
    }
  }

  confirmReturn(config: ConfirmOptions) {
    const params: any = {};
    if (config.disableOffclick) {
      params.windowClass = 'click-disabled';
    }
    const modalRef = this.modalService.open( BellConfirmComponent, params);
    modalRef.componentInstance.type = config.type;
    modalRef.componentInstance.body = config.body;
    modalRef.componentInstance.buttons = config.buttons;
    if (config.title) {
      modalRef.componentInstance.title = config.title;
    }
    if (config.size) {
      modalRef.componentInstance.size = config.size;
    }

    return modalRef;
  }

  error(config: ErrorOptions) {
    const params: any = {};
    if (config.disableOffclick) {
      params.windowClass = 'click-disabled';
    }
    const modalRef = this.modalService.open( BellErrorComponent, params);
    modalRef.componentInstance.errors = config.errors;
    modalRef.componentInstance.buttons = config.buttons;
    if (config.title) {
      modalRef.componentInstance.title = config.title;
    }
    if (config.size) {
      modalRef.componentInstance.size = config.size;
    }
  }

}

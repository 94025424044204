<div class="bell-modal confirm {{type}} {{size}}">
  <div class="bell-modal__header" *ngIf="showTitle">
    <h2 class="bell-modal__title">{{ title }}</h2>
    <span class="bell-modal__close" (click)="close()"></span>
  </div>
  <div class="bell-modal__body" [innerHTML]="body"></div>
  <div class="bell-modal__footer">
      <button
        *ngFor="let button of buttons; let i = index"
        (click)="onClick(button)"
        [ngClass]="{'primary': i === buttons.length - 1, 'cancel': button.role === 'cancel' }">
        {{ button.text | translate }}
      </button>
  </div>
</div>

import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalButton } from '../../models/button';

@Component({
  selector: 'bell-alert',
  templateUrl: './alert.component.html'
})
export class BellAlertComponent implements OnInit {
  @Input() type: 'default' | 'danger' | 'warning' = 'default';
  @Input() title: string = '';
  @Input() body: string | undefined;
  @Input() button: any;
  @Input() showTitle: boolean = true;
  @Input() size: 'small' | 'medium' | 'large' | 'extra-large' = 'small';

  constructor(
    private activeModal: NgbActiveModal
  ) {}

  ngOnInit(): void {
    if (this.title === '') {this.showTitle = false; }
    this.setDefaultButton();
  }

  close() {
    this.activeModal.dismiss();
  }

  setDefaultButton() {
    if (!this.button) {
      this.button = {
        text: 'Ok'
      };
    }
  }

  onClick(button: ModalButton) {
    if (button.role === 'cancel') {
      this.activeModal.dismiss();
    }
    if (button.role !== 'cancel') {
      this.activeModal.close(button.role);
    }
    if (button.handler) {
      button.handler();
      this.activeModal.close();
    }
  }
}
